<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <!-- <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('cropInfo.damage_info') }}</h4>
          </template> -->
          <template v-slot:body>
              <b-row>
                <b-col lg="12" sm="12" class="form-for-textarea">
                  <b-overlay :show="loading">
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                      <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                        <b-row>
                          <b-col lg="6" sm="12">
                              <ValidationProvider name="Fiscal Year" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="fiscal_year_id"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{ $t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                        plain
                                        v-model="form.fiscal_year_id"
                                        :options="fiscalList"
                                        id="fiscal_year_id"
                                        :disabled="disableButton"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                          <template v-slot:first>
                                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                            <b-col lg="6" sm="12">
                            <ValidationProvider name="Production Season" vid="region_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="region"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('seedsConfig.productionSeason') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="form.production_season_id"
                                  :options="productionSeasonList"
                                  id="production_season_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="disableButton"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <iq-card>
                            <template v-slot:headerTitle>
                              <h4 class="card-title" style="width: 100%"> {{ $t('cropInfo.crop_info') }}</h4>
                            </template>
                        </iq-card>
                        <b-table-simple bordered>
                          <b-tr>
                            <b-th class="text-center"> {{ $t('globalTrans.sl_no') }} </b-th>
                            <b-th class="text-center"> {{ $t('germplasmReport.crop_type') }}</b-th>
                            <b-th class="text-center"> {{ $t('cropInfo.crop_sub_type') }}</b-th>
                            <b-th class="text-center"> {{ $t('cropInfo.cropName') }}</b-th>
                            <b-th class="text-center"> {{ $t('seedsConfig.category') }}</b-th>
                            <b-th class="text-center"> {{ $t('cropInfo.land_target') }}</b-th>
                            <b-th class="text-center"> {{ $t('cropInfo.achievement_land') }}</b-th>
                            <b-th class="text-center"> {{ $t('cropInfo.production_target') }}</b-th>
                            <b-th class="text-center"> {{ $t('cropInfo.production_achievement') }}</b-th>
                            <b-th class="text-center"> {{ $t('cropInfo.crop_harvesting') }}</b-th>
                          </b-tr>
                          <template v-if="cropData.length > 0">
                            <template v-for="(list, cropTypeIndex) in cropData">
                              <template v-for="(subType, subTypeIndex) in list.sub_type_list" v-key="subTypeIndex">
                                  <template v-for="(cropItem, cropIndex) in subType.crop_list">
                                    <b-tr v-for="(catItem, index) in cropItem.category_list" :key="index">
                                      <slot v-if="subTypeIndex===0 && cropIndex === 0 && index === 0">
                                        <b-td class="text-center align-middle" :rowspan="cropRowspan(list.sub_type_list)">{{ $n(catItem.count) }}</b-td>
                                        <td class="text-center align-middle" :rowspan="cropRowspan(list.sub_type_list)">
                                          {{ getCropTypeName(list.crop_type_id) }}
                                        </td>
                                      </slot>
                                      <slot v-if="cropIndex == 0 && index == 0">
                                        <td class="text-center align-middle" :rowspan="categoryRowspan(subType.crop_list)">
                                          {{ getCropSubTypeName(subType.sup_type_id) }}
                                        </td>
                                      </slot>
                                      <slot v-if="index === 0">
                                        <td class="text-center align-middle" :rowspan="cropItem.category_list.length">
                                          {{ getCropName(cropItem.crop_name_id)}}
                                        </td>
                                      </slot>
                                      <td>
                                        {{ catItem.category_id !== 0 ? getCategoryName(catItem.category_id) : '' }}
                                      </td>
                                      <b-td>
                                        {{ $n(catItem.land_target, { useGrouping: false }) }}
                                      </b-td>
                                      <b-td>
                                        {{ $n(catItem.land_achievement, { useGrouping: false }) }}
                                      </b-td>
                                      <b-td>
                                        {{ $n(catItem.production_target, { useGrouping: false }) }}
                                      </b-td>
                                      <b-td>
                                        <ValidationProvider name="Production Achievement" :vid="'cropData.'+cropTypeIndex+'.sub_type_list.'+subTypeIndex+'.crop_list.'+cropIndex+'.category_list.'+index+'.production_achievement'" rules="">
                                            <b-form-group
                                                label-for="production_achievement"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <b-form-input
                                                  :id="'production_achievement['+index+']'"
                                                  v-model="catItem.production_achievement"
                                                  :placeholder="$t('cropInfo.production_achievement')"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                      </b-td>
                                      <b-td>
                                        <ValidationProvider name="Crop harvesting" :vid="'cropData.'+cropTypeIndex+'.sub_type_list.'+subTypeIndex+'.crop_list.'+cropIndex+'.category_list.'+index+'.crop_harvesting'" rules="">
                                          <b-form-group
                                                  label-for="crop_harvesting"
                                                  slot-scope="{ valid, errors }"
                                                  >
                                                  <b-form-input
                                                    :id="'crop_harvesting['+index+']'"
                                                    v-model="catItem.crop_harvesting"
                                                    :placeholder="$t('cropInfo.crop_harvesting')"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-td>
                                    </b-tr>
                                  </template>
                                </template>
                              </template>
                          </template>
                          <template v-else>
                            <b-tr>
                              <b-td colspan="10" class="text-center">{{ $t('globalTrans.noDataFound') }}</b-td>
                            </b-tr>
                          </template>
                        </b-table-simple>
                        <b-row>
                          <b-col lg="4" sm="12">
                            <ValidationProvider name="Date" vid="harvesting_date" rules="required">
                              <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="harvesting_date"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('globalTrans.date')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input class="form-control"
                                    v-model="form.harvesting_date"
                                    placeholder="Select Date"
                                    id="harvesting_date"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                              </ValidationProvider>
                          </b-col>
                        </b-row>
                        <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                        </div>
                      </b-form>
                    </ValidationObserver>
                  </b-overlay>
                </b-col>
              </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { productionAchievementStore, getHarvestCropListData, productionAchievementAllList, productionAchievementUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id', 'item'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.item) {
      this.form.fiscal_year_id = this.item.fiscal_year_id
      this.form.production_season_id = this.item.production_season_id
      this.form.harvesting_date = this.item.harvesting_date
      this.disableButton = true
      this.getEditData()
    } else {
      this.form = Object.assign({}, this.form, {
      fiscal_year_id: this.$store.state.SeedsFertilizer.currentFiscalYearId
     })
    }
  },
  mounted () {
    core.index()
    flatpickr('#harvesting_date', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      form: {
        id: '',
        fiscal_year_id: 0,
        production_season_id: 0,
        details: []
      },
      cropData: [],
      disableButton: false
    }
  },
  computed: {
    productionSeasonList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.filter(item => item.status === 1)
    },
    fiscalList: function () {
      return this.$store.state.commonObj.fiscalYearList
    },
    loading () {
        return this.$store.state.commonObj.loading
      }
  },
  watch: {
      'form.production_season_id': function (newVal, oldVal) {
        if (!this.item) {
        this.getSeasonInformation(newVal)
        }
      }
  },
  methods: {
    cropRowspan (checkData) {
        let total = 0
        checkData.forEach((element, key) => {
          element.crop_list.forEach((element2, key2) => {
            element2.category_list.forEach((element3, key2) => {
              total++
            })
          })
        })
        return total
    },
    categoryRowspan (checkData) {
        let total = 0
        checkData.forEach((element, key) => {
          element.category_list.forEach((element2, key2) => {
            total++
          })
        })
        return total
    },
    async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: true }
      this.form.details = this.cropData
      // result = await RestApi.postData(seedFertilizerServiceBaseUrl, harvestProStore, this.form)
      if (this.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${productionAchievementUpdate}/${this.id}`, this.form)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, productionAchievementStore, this.form)
      }
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$toast.success({
            message: result.message,
            color: '#D6E09B'
        })
        // this.$refs.form.setErrors(result.errors)
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
    },
    getEditData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        const params = Object.assign({}, { id: this.item.id })
        RestApi.getData(seedFertilizerServiceBaseUrl, productionAchievementAllList, params).then(response => {
              if (response.success) {
                this.cropData = response.data
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false })
          })
      },
    getSeasonInformation (ID) {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, { production_season_id: ID, fiscal_year_id: this.form.fiscal_year_id })
      RestApi.getData(seedFertilizerServiceBaseUrl, getHarvestCropListData, params).then(response => {
            if (response.success) {
              this.cropData = response.data
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false })
        })
    },
    getCategoryName (ID) {
      return this.$store.state.SeedsFertilizer.commonObj.seedCategoryList.find(item => item.value === ID).text
    },
    getCropTypeName (ID) {
      return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.find(item => item.value === ID).text
    },
    getCropSubTypeName (ID) {
      if (ID > 0) {
        return this.$store.state.SeedsFertilizer.commonObj.seedSubTypeList.find(item => item.value === ID).text
      } else {
        return ' '
      }
    },
    getCropName (ID) {
      return this.$store.state.SeedsFertilizer.commonObj.CropNameList.find(item => item.value === ID).text
    },
    validationProductionAchievement (cropTypeIndex, subTypeIndex, cropIndex, categoryIndex) {
          const category = this.cropData[cropTypeIndex].sub_type_list[subTypeIndex].crop_list[cropIndex].category_list[categoryIndex]
          const maxLandAchievement = parseFloat(category.land_target) - parseFloat(category.previous_production_achievement)
          if (parseFloat(category.production_achievement) > maxLandAchievement) {
            this.$nextTick(() => {
              category.production_achievement = maxLandAchievement
              return this.$toast.error({
                title: this.$t('globalTrans.error'),
                message: 'Production achievement can not be larger than ' + maxLandAchievement,
                color: '#D6E09B'
              })
            })
          }
      },
    validationCropHarvesting (cropTypeIndex, subTypeIndex, cropIndex, categoryIndex) {
          const category = this.cropData[cropTypeIndex].sub_type_list[subTypeIndex].crop_list[cropIndex].category_list[categoryIndex]
          const maxLandAchievement = parseFloat(category.land_target) - parseFloat(category.previous_crop_harvesting)
          if (parseFloat(category.crop_harvesting) > maxLandAchievement) {
            this.$nextTick(() => {
              category.crop_harvesting = maxLandAchievement
              return this.$toast.error({
                title: this.$t('globalTrans.error'),
                message: 'Crop Harvesting can not be larger than ' + maxLandAchievement,
                color: '#D6E09B'
              })
            })
          }
      }
  }
}
</script>
